import React, {useState, useEffect, useMemo} from 'react';
import styled from 'styled-components';
import * as L from '../Utils/Lang';
import * as Widget from './Widget';
import * as SvgIcon from './SvgIcon';
import {Color, UnderlineButton} from './Widget';
import * as GeolocationContext from '../Contexts/GeolocationContext';
import RectButton, {BUTTON_SIZE, BUTTON_SKIN} from './RectButton';

const dayDisplayMap = {
  1: 'store__day_sun',
  2: 'store__day_mon',
  3: 'store__day_tue',
  4: 'store__day_wed',
  5: 'store__day_thu',
  6: 'store__day_fri',
  7: 'store__day_sat',
};

function StoreItem(props) {
  const {
    mobile = false,
    searchResult = false,
    store,
    storeIdx,
    isActive,
    setActiveStore,
    onClose = () => 0,
  } = props;
  const [expand, setExpand] = React.useState(false);

  if (!store) {
    return null;
  }

  return (
    <Widget.FlexCol
      id={`item_${store.id}`}
      style={{
        padding: mobile ? '0px' : '20px 24px',
        borderBottom: '1px solid rgba(20, 20, 20, 0.1)',
        backgroundColor: isActive ? 'rgba(20, 20, 20, 0.03)' : 'white',
        cursor: 'pointer',
      }}>
      <Widget.FlexRow
        onClick={() => {
          setActiveStore(isActive ? null : store.id);
        }}
        style={{
          padding: mobile ? '20px 24px' : '0px',
          alignItems: 'flex-start',
        }}>
        <Widget.FlexCenter
          style={{
            backgroundColor: 'black',
            width: 32,
            height: 32,
            borderRadius: '50%',
            color: 'white',
          }}>
          {storeIdx + 1}
        </Widget.FlexCenter>
        <div
          style={{
            flex: 1,
            marginLeft: 20,
            marginRight: 10,
            alignSelf: 'stretch',
          }}>
          <div
            style={{fontWeight: 'bold', fontSize: 16, color: Color.mainDark}}>
            {L.d(store.name)}{' '}
            {mobile && store.distance !== null && (
              <span
                style={{paddingLeft: 8, fontWeight: 'normal', fontSize: 14}}>
                {Math.round(store.distance * 10) / 10} km
              </span>
            )}
          </div>
          <div style={{marginBottom: 8}}>{L.d(store.address) || ''}</div>

          {mobile && (
            <UnderlineButton
              style={{marginBottom: expand ? 12 : 0}}
              onClick={() => {
                window.open(`tel:${store.phone}`, '_self');
              }}>
              {store.tel}
            </UnderlineButton>
          )}

          {(!mobile || searchResult) && (
            <Widget.FlexRow
              onClick={evt => {
                evt.stopPropagation();
                setExpand(!expand);
              }}>
              <div style={{color: 'gray', marginRight: 10}}>
                {searchResult ? '營業時間' : '門市資訊'}
              </div>
              {expand ? (
                <SvgIcon.Minus w={20} h={20} />
              ) : (
                <SvgIcon.Plus w={20} h={20} />
              )}
            </Widget.FlexRow>
          )}

          {expand && (
            <div style={{marginTop: 16}}>
              {!mobile && (
                <UnderlineButton
                  style={{marginBottom: 12}}
                  onClick={() => {
                    window.open(`tel:${store.phone}`, '_self');
                  }}>
                  {store.tel}
                </UnderlineButton>
              )}

              {searchResult ? null : <div>營業時間</div>}
              <div
                style={{
                  marginBottom: 12,
                  whiteSpace: 'pre-wrap',
                  fontSize: 14,
                  color: Color.mainDark,
                }}>
                {store.openTime.map((ot, idx) => (
                  <div key={idx}>{`${L.s(dayDisplayMap[idx + 1])} ${ot[0]}-${
                    ot[1]
                  }`}</div>
                ))}
              </div>
              {!mobile && (
                <Widget.FlexRow>
                  <StoreItemButton
                    onClick={e => {
                      e.stopPropagation();
                      window.open(`tel:${store.phone}`, '_self');
                    }}
                    haveMarginRight>
                    電話聯繫
                  </StoreItemButton>
                  <StoreItemButton
                    onClick={e => {
                      e.stopPropagation();
                      window.open(
                        `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                          `${store.lat},${store.lng}`,
                        )}`,
                      );
                    }}>
                    路線導航
                  </StoreItemButton>
                </Widget.FlexRow>
              )}
            </div>
          )}
        </div>
        {!mobile ? (
          store.distance !== null && (
            <div style={{fontSize: 14, color: Color.mainDark, opacity: 0.7}}>
              {Math.round(store.distance * 10) / 10} km
            </div>
          )
        ) : searchResult ? (
          <div
            onClick={evt => {
              evt.stopPropagation();
              onClose();
            }}>
            <SvgIcon.Cross w={20} h={20} />
          </div>
        ) : (
          <div
            onClick={evt => {
              evt.stopPropagation();
              setExpand(!expand);
            }}>
            {expand ? (
              <SvgIcon.DropUp w={24} h={24} />
            ) : (
              <SvgIcon.DropDown w={24} h={24} />
            )}
          </div>
        )}
      </Widget.FlexRow>
      {mobile && (
        <Widget.FlexRow
          style={{
            justifyContent: 'center',
            marginBottom: searchResult ? 0 : 20,
          }}>
          <RectButton
            onClick={() => {
              window.open(`tel:${store.phone}`, '_self');
            }}
            style={{flex: 1}}
            text="電話聯繫"
          />
          <RectButton
            style={{flex: 1}}
            skin={BUTTON_SKIN.PRIMARY}
            onClick={() => {
              window.open(
                `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
                  `${store.lat},${store.lng}`,
                )}`,
              );
            }}
            text="路線導航
"
          />
        </Widget.FlexRow>
      )}
    </Widget.FlexCol>
  );
}

const StoreItemButton = styled.div`
  ${props =>
    props.mobile
      ? `min-width: calc(50% - 16px);
    border: 1px solid ${Color.mainDark};
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${
      props.haveBgColor
        ? `background-color: ${Color.mainDark}; color: white;`
        : `background-color: white;`
    };
    ${props.searchResult ? `flex: 1;` : ``}
    `
      : `text-decoration: underline;
    font-weight: bold;
    font-size: 14px;
    color: ${Color.mainDark};
    cursor: pointer;`};

  ${props => (props.haveMarginRight ? `margin-right: 16px` : '')};
`;

export default GeolocationContext.withConsumer(StoreItem);
