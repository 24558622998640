import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import ActionCreator from '../ActionCreator';
import * as AppContext from '../Contexts/AppContext';
import {Actions, NavActions} from '../Contexts/AppContext';
import * as Geolocation from '../Contexts/GeolocationContext';
import * as L from '../Utils/Lang';
import * as AppHooks from '../Hooks/AppHooks';
import * as Widget from '../Components/Widget';
import {Color, FlexRow, FontSize, LineHeight} from '../Components/Widget';
import * as SvgIcon from '../Components/SvgIcon';
import StoreMap from '../Components/Map';
import StoreItem from '../Components/StoreItem';
import StoreSearchBar from '../Components/StoreSearchBar';
import calcDistance from '../Utils/calcDistance';
import {brandsDisplayArr} from '../Domain/Brand';
import Alert from '../Components/Modal/Alert';
import Location from '../images/grey-location.png';

const queryString = require('query-string');

const Districts = [
  {display: '北部', value: 'north', weight: 0},
  {display: '中部', value: 'center', weight: 1},
  {display: '南部', value: 'south', weight: 2},
];

function useQueryString({path, location}) {
  const [storeId, setStoreId] = useState(null);
  const [isInApp, setIsInApp] = useState(null);
  const [curLocationInUrl, setCurLocationInUrl] = useState(null);

  function setActiveStore(id, nextBrand = null) {
    let _basePath = '';
    let _extraQuery = '';

    // path = "/stores"
    if (id === null) {
      _basePath = `${path}`;
    } else {
      _basePath = `${path}?storeId=${id}`;
    }

    if (nextBrand) {
      if (_basePath.indexOf('?') !== -1) {
        _basePath += '&brand=' + nextBrand;
      } else {
        _basePath += '?brand=' + nextBrand;
      }
    }

    if (location.search) {
      _extraQuery = '';
      try {
        let parsed = queryString.parse(location.search);
        delete parsed.storeId;
        delete parsed.brand;
        _extraQuery = queryString.stringify(parsed);
      } catch (err) {}

      if (_extraQuery) {
        if (_basePath.indexOf('?') !== -1) {
          _extraQuery = '&' + _extraQuery;
        } else {
          _extraQuery = '?' + _extraQuery;
        }
      }
    }

    NavActions.navigate(`${_basePath}${_extraQuery}`);
  }

  React.useEffect(() => {
    try {
      const parsed = queryString.parse(location.search);
      setStoreId(parsed.storeId);
      if (!!parsed.app) {
        setIsInApp(true);
      } else {
        setIsInApp(false);
      }

      if (parsed.userLocation) {
        let _curLocationInUrl = null;
        try {
          _curLocationInUrl = JSON.parse(parsed.userLocation);
        } catch (err) {
          _curLocationInUrl = null;
        }

        setCurLocationInUrl(_curLocationInUrl);
      }
    } catch (ex) {
      console.warn('cannot parse query string', ex);
    }
  }, [location.search, path, setStoreId]);

  return {activeStore: storeId, setActiveStore, isInApp, curLocationInUrl};
}

function StoresPage(props) {
  const {app} = props;
  const activeBrand = AppContext.Actions.getActiveBrand();
  const {dimension, mobile, tablet} = AppHooks.useDimension();
  const {
    activeStore,
    setActiveStore: _setActiveStore,
    isInApp,
    curLocationInUrl,
  } = useQueryString({
    path: '/stores',
    location: props.location,
  });

  const [stores, setStores] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [showMobileStoreList, setShowMobileStoreList] = useState(false);
  const [showMobileStoreDetail, setShowMobileStoreDetail] = useState(false);
  const [
    showMobileBrandSelectDropdown,
    setShowMobileBrandSelectDropdown,
  ] = useState(false);
  const scrollRef = useRef(null);

  useEffect(() => {
    if (!scrollRef.current) {
      return;
    }
    const item = scrollRef.current.querySelector(`#item_${activeStore}`);

    scrollRef.current.scroll(0, item ? item.offsetTop - 270 : 0);
  }, [activeStore]);

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    document.documentElement.style.overflow = 'hidden';
    document.body.scroll = 'no';
    return () => {
      document.documentElement.style.overflow = 'scroll';
      document.body.scroll = 'yes';
    };
  }, []);

  useEffect(() => {
    async function getBrandStores() {
      setStores(await AppContext.Actions.getBrandStores());
    }

    getBrandStores();

    if (!isInApp && isInApp !== null) {
      Geolocation.Actions.askPermisssion();
      Geolocation.Actions.subscribeLocationWatcher();
    }

    return () => {
      !isInApp &&
        isInApp !== null &&
        Geolocation.Actions.unsubscribeLocationWatcher();
    };
  }, [activeBrand, isInApp]);

  useEffect(() => {
    if (props.geolocation.permission === false) {
      Actions.setGlobalModalContent(
        mobile || tablet ? (
          <Alert
            icon={
              <img
                style={{width: 120, height: 120}}
                src={Location}
                alt="location"
              />
            }
            title={L.d({
              zh: '無法讀取您的位置',
              en: 'store__location_not_found',
            })}
            subTitle={null}
            cancelLabel={L.s('c__alert_confirm')}
          />
        ) : (
          <Alert
            icon={
              <img
                style={{width: 120, height: 120}}
                src={Location}
                alt="location"
              />
            }
            title={L.s('store__location_not_found')}
            subTitle={L.s('store__need_location_permission')}
            cancelLabel={L.s('c__alert_confirm')}
          />
        ),
      );
    }
  }, [props.geolocation.permission]);

  let curLocation = curLocationInUrl || props.geolocation.curLocation;

  if (!stores || !dimension) {
    return <div style={{height: '100vh'}} />;
  }

  const activeSearch = !!searchValue;

  let resultStores = stores.map(s => ({
    ...s,
    distance: curLocation
      ? calcDistance(
          {lat: s.lat, lng: s.lng},
          {lat: curLocation.lat, lng: curLocation.lng},
        )
      : null,
  }));

  async function setActiveStore(storeId, nextBrand = null) {
    if (!storeId) {
      const resp = await AppContext.Actions.getBrandStores({
        forceUpdate: false,
        brand: nextBrand,
      });
      setStores(resp);
      const results = resp.map(s => ({
        ...s,
        distance: curLocation
          ? calcDistance(
              {lat: s.lat, lng: s.lng},
              {lat: curLocation.lat, lng: curLocation.lng},
            )
          : null,
      }));
      let closestStore = {};
      results.forEach(s => {
        closestStore = closestStore.distance < s.distance ? closestStore : s;
      });
      _setActiveStore(closestStore.id, nextBrand);
      setShowMobileStoreDetail(true);
    } else {
      _setActiveStore(storeId, nextBrand);
      setShowMobileStoreDetail(true);
    }
  }

  if (activeSearch) {
    resultStores = resultStores.filter(
      store =>
        L.d(store.name)
          .toLocaleLowerCase()
          .indexOf(searchValue.toLowerCase()) > -1,
    );
  } else {
    resultStores = resultStores
      .slice()
      .sort((a, b) => (curLocation ? a.distance - b.distance : 0))
      .sort((a, b) => {
        const districtA = Districts.find(d => d.value === a.district);
        const districtB = Districts.find(d => d.value === b.district);
        if (districtA && districtB) {
          if (districtA.weight > districtB.weight) {
            return 1;
          } else if (districtA.weight < districtB.weight) {
            return -1;
          }
        }
        return 0;
      });

    if (curLocation && !initialized) {
      let closestStore = {};
      resultStores.forEach(s => {
        closestStore = closestStore.distance < s.distance ? closestStore : s;
      });
      setActiveStore(closestStore.id);
      setInitialized(true); // only set closest store once
    }
  }

  if (!isInApp && dimension?.innerWidth > app.breakpoints.tablet) {
    return (
      <Wrapper data-nav-type="solid">
        <div className="search">
          <div className="search-bar">
            <StoreSearchBar
              value={searchValue}
              setSearchValue={setSearchValue}
              onBrandChange={brand => setActiveStore(null, brand)}
            />
          </div>
          <div ref={scrollRef} className="search-result">
            {activeSearch ? (
              <>
                <div style={{fontSize: 20, color: Color.MainDark}}>
                  {resultStores.length} 筆搜尋結果
                </div>
                <SearchResultStores
                  resultStores={resultStores}
                  activeStore={activeStore}
                  setActiveStore={setActiveStore}
                  mobile={false}
                />
              </>
            ) : (
              <StoresGroupByDistrict
                resultStores={resultStores}
                activeStore={activeStore}
                setActiveStore={setActiveStore}
                mobile={false}
              />
            )}
          </div>
        </div>

        <div className="map">
          <StoreMap
            height={null}
            stores={resultStores}
            activeStoreId={activeStore}
            setActiveStoreId={setActiveStore}
            curLocation={curLocation}
            isInApp={isInApp}
          />
        </div>
      </Wrapper>
    );
  }

  return (
    <MobileWrapper>
      <div style={{position: 'relative'}}>
        <MobileNavBar>
          {!isInApp && !showMobileBrandSelectDropdown && (
            <SvgIcon.ChevronLeft
              style={{
                position: 'absolute',
                left: 16,
              }}
              w={28}
              h={28}
              onClick={() => {
                NavActions.navigate('/home');
              }}
            />
          )}
          <Widget.FlexCenter
            onClick={() => {
              setShowMobileBrandSelectDropdown(!showMobileBrandSelectDropdown);
            }}
            style={{
              flex: 1,
              fontWight: 'bold',
              fontSize: 16,
              cursor: 'pointer',
              position: 'relative',
            }}>
            {activeBrand.toUpperCase()} 門市
            <SvgIcon.DropDown color={Color.mainDark} w={28} h={28} />
          </Widget.FlexCenter>
          <div
            style={{
              display: showMobileBrandSelectDropdown ? 'none' : 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              right: 16,
            }}
            onClick={() => setShowMobileStoreList(!showMobileStoreList)}>
            {showMobileStoreList ? (
              <SvgIcon.Map w={28} h={28} />
            ) : (
              <SvgIcon.List w={28} h={28} />
            )}
          </div>
        </MobileNavBar>

        {showMobileBrandSelectDropdown /* this is mobile version */ && (
          <MobileBrandSelectDropdownWrapper
            open={showMobileBrandSelectDropdown}>
            <div className="backdrop" />
            <div className="content">
              {brandsDisplayArr.map((b, idx) => (
                <div
                  className={`brand-item${
                    b.key === activeBrand ? ' active' : ''
                  }`}
                  key={idx}
                  onClick={() => {
                    AppContext.Actions.setActiveBrand(b.key);
                    setActiveStore(null, b.key);
                    setShowMobileBrandSelectDropdown(false);
                  }}>
                  <div className="display">{b.display} 門市</div>
                </div>
              ))}
            </div>
          </MobileBrandSelectDropdownWrapper>
        )}
      </div>

      <div style={{margin: '4px 35px 12px'}}>
        <StoreSearchBar
          mobile
          value={searchValue}
          setSearchValue={setSearchValue}
          setShowMobileStoreDetail={setShowMobileStoreDetail}
          onBrandChange={brand => setActiveStore(null, brand)}
        />
      </div>

      {showMobileStoreList && (
        <MobileStoreListOverlay>
          {activeSearch ? (
            <>
              <div
                style={{marginBottom: 8, fontSize: 20, color: Color.MainDark}}>
                {resultStores.length} 筆搜尋結果
              </div>
              <SearchResultStores
                resultStores={resultStores}
                activeStore={activeStore}
                setActiveStore={setActiveStore}
                mobile={true}
              />
            </>
          ) : (
            <StoresGroupByDistrict
              resultStores={resultStores}
              activeStore={activeStore}
              setActiveStore={setActiveStore}
              mobile={true}
            />
          )}
        </MobileStoreListOverlay>
      )}

      <StoreMap
        height={dimension.innerHeight - 120}
        stores={resultStores}
        activeStoreId={activeStore}
        setActiveStoreId={setActiveStore}
        curLocation={curLocation}
        isInApp={isInApp}
      />

      {/* TODO: click mark point in map, and show this store search result overlay. */}
      <MobileSearchResultOverlay show={showMobileStoreDetail}>
        <div style={{position: 'relative'}}>
          {resultStores.length <= 0 && (
            <div style={{padding: '35px 20px'}}>
              <FlexRow style={{justifyContent: 'space-between'}}>
                <div
                  style={{
                    fontSize: 16,
                    color: Color.mainDark_50,
                    fontWeight: 300,
                  }}>
                  查無門市
                </div>
                <SvgIcon.Cross
                  onClick={() => setShowMobileStoreDetail(false)}
                />
              </FlexRow>
            </div>
          )}
          {resultStores.length > 0 && (
            <StoreItem
              mobile
              searchResult
              storeIdx={
                resultStores.findIndex(s => s.id.toString() === activeStore) ||
                0
              }
              store={
                resultStores.find(s => s.id.toString() === activeStore) ||
                resultStores[0]
              }
              isActive={false}
              setActiveStore={() => 0}
              onClose={() => setShowMobileStoreDetail(false)}
            />
          )}
        </div>
      </MobileSearchResultOverlay>
    </MobileWrapper>
  );
}

function StoresGroupByDistrict({
  resultStores,
  activeStore,
  setActiveStore,
  mobile,
}) {
  return Districts.reduce((acc, district, idx) => {
    const startAt = acc[idx - 1] ? acc[idx - 1].endAt : 0;
    const next = {
      ...district,
      startAt,
      endAt:
        startAt +
        resultStores.filter(s => s.district === district.value).length,
    };
    acc.push(next);
    return acc;
  }, []).map((district, idx) => (
    <div key={`district-${idx}`} style={{marginTop: idx > 0 ? 24 : 0}}>
      {resultStores.filter(store => store.district === district.value).length >
        0 && (
        <div style={{fontSize: 20, color: Color.MainDark}}>
          {district.display}
        </div>
      )}
      {resultStores
        .filter(store => store.district === district.value)
        .sort((a, b) => a.distance - b.distance)
        .map((store, idx) => (
          <StoreItem
            key={idx}
            storeIdx={district.startAt + idx}
            store={store}
            isActive={store.id.toString() === activeStore}
            setActiveStore={setActiveStore}
            mobile={mobile}
          />
        ))}
    </div>
  ));
}

function SearchResultStores({
  resultStores,
  activeStore,
  setActiveStore,
  mobile,
}) {
  return resultStores.length > 0 ? (
    resultStores.map((store, idx) => (
      <StoreItem
        key={idx}
        storeIdx={idx}
        store={store}
        isActive={store.id.toString() === activeStore}
        setActiveStore={setActiveStore}
        mobile={mobile}
      />
    ))
  ) : (
    <Widget.FlexCenter
      style={{
        width: '100%',
        height: '100%',
        fontSize: 16,
        color: 'rgba(20, 20, 20, 0.5)',
      }}>
      查無門市
    </Widget.FlexCenter>
  );
}

const MobileNavBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--scroll-bar-height);
  height: var(--navbar-height);
  background-color: white;
  padding: 10px 35px;
  z-index: 5;
`;

const MobileSearchResultOverlay = styled.div`
  position: fixed;
  z-index: 3;
  background-color: white;
  bottom: ${props => (props.show ? 0 : '-500')}px;
  left: 0;
  width: 100vw;
  transition: 300ms;

  & .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    color: black;
  }
`;

const MobileStoreListOverlay = styled.div`
  position: fixed;
  left: 0;
  width: 100vw;
  height: calc(100vh - 116px);
  overflow-y: auto;
  background-color: white;
  z-index: 5;
  padding: 0px 35px 32px;
`;

const MobileWrapper = styled.div`
  position: relative;
  overflow-y: hidden;

  & .search {
  }

  & .map {
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  border-top: 1px solid #e7e7e7;
  height: calc(100vh - var(--navbar-height));

  & .search {
    flex: 1 0 300px;
    background-color: white;
    display: flex;
    flex-direction: column;

    & .search-bar {
      background-color: white;
      flex-shrink: 0;
    }

    & .search-result {
      flex: 1;
      overflow: auto;
      padding: 0px 50px 32px;
      scroll-behavior: smooth;
    }
  }

  & .map {
    flex: 2 1 400px;
    height: 100%;
    background-color: #ccc;
  }
`;

const MobileBrandSelectDropdownWrapper = styled.div`
  position: fixed;
  width: 100%;
  z-index: 10; /* higher than bottom active store panel: 3 */
  & > .content {
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0;
    top: 0;
    border-top: 1px solid ${Color.mainDark_10};
    background-color: white;
    padding-bottom: 8px;
    padding-top: 8px;

    & > .header {
      padding: 32px 50px 20px;
    }
    & > .brand-item {
      font-size: ${FontSize.meta}px;
      line-height: ${LineHeight.meta}px;
      padding: 6px 35px;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: flex-end;
      & > .display {
      }
    }

    & > .brand-item.active {
      font-weight: bold;
      & > .display {
        border-bottom: 1px solid ${Color.mainDark};
      }
    }
  }

  & > .backdrop {
    opacity: ${props => (props.open ? '1' : '0')};
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: calc(
      100vh - var(--navbar-height)
    ); /* this is walkaround to set height */
    position: absolute;
    top: 0px;
    left: 0px;
  }
`;

export default AppContext.withConsumer(
  Geolocation.withConsumer(connect(null, ActionCreator)(StoresPage)),
);
