import React, {useState} from 'react';
import styled from 'styled-components';
import * as AppContext from '../Contexts/AppContext';
import * as Widget from '../Components/Widget';
import * as SvgIcon from '../Components/SvgIcon';
import {Color, FontSize, LineHeight} from './Widget';
import {brandsDisplayArr} from '../Domain/Brand';

function StoreSearchBar(props) {
  let {
    onBrandChange,
    mobile,
    setSearchValue,
    setShowMobileStoreDetail = () => 0,
  } = props;
  const activeBrand = AppContext.Actions.getActiveBrand();
  const breakpoints = AppContext.Actions.getBreakpoints();

  const [showDropdown, setShowDropdown] = useState(false); // consider to extract this

  return (
    <SearchBarWrapper
      style={{backgroundColor: 'white'}}
      breakpoints={breakpoints}>
      {!mobile && (
        <Widget.FlexRow style={{marginBottom: 12, cursor: 'pointer'}}>
          <div
            style={{
              flex: 1,
              fontWeight: 'bold',
              fontSize: 30,
              color: Color.mainDark,
            }}>
            {activeBrand.toUpperCase()} 門市
          </div>
          <div
            onClick={() => setShowDropdown(!showDropdown)}
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: 14,
              color: Color.mainDark_70,
            }}>
            Other brands
            <SvgIcon.DropDown color={Color.mainDark} />
          </div>
        </Widget.FlexRow>
      )}

      {showDropdown /* this is desktop version */ && (
        <DropdownWrapper open={showDropdown}>
          <div className="backdrop" />
          <div className="content">
            {/* duplicate of search */}
            <div className="header">
              <Widget.FlexRow
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => setShowDropdown(!showDropdown)}>
                <div
                  style={{
                    flex: 1,
                    fontWeight: 'bold',
                    fontSize: 30,
                    color: Color.mainDark,
                    opacity: 0,
                  }}>
                  {activeBrand.toUpperCase()} 門市
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: 14,
                    color: Color.mainDark_70,
                  }}>
                  Other brands
                  <SvgIcon.DropDown color={Color.mainDark} />
                </div>
              </Widget.FlexRow>
            </div>
            {/* end duplicate of search */}

            {brandsDisplayArr.map((b, idx) => (
              <div
                className={`brand-item${
                  b.key === activeBrand ? ' active' : ''
                }`}
                key={idx}
                onClick={() => {
                  AppContext.Actions.setActiveBrand(b.key);
                  setShowDropdown(false);
                  onBrandChange(b.key);
                }}>
                <div className="display">{b.display} 門市</div>
              </div>
            ))}
          </div>
        </DropdownWrapper>
      )}

      <Widget.FlexRow
        style={{width: '100%', border: '1px solid black', height: 44}}>
        <input
          value={props.value}
          onChange={e => {
            setSearchValue(e.target.value);
            setShowMobileStoreDetail(true);
          }}
          className="search-input"
          placeholder="搜尋門市名稱"
          style={{flex: 1, fontSize: 14, color: Color.mainDark}}
        />
        <SvgIcon.CrossCircle
          onClick={() => {
            setSearchValue('');
          }}
          style={{
            marginRight: '16px',
            cursor: 'pointer',
            visibility: props.value ? 'visible' : 'hidden',
          }}
        />
        <Widget.FlexCenter
          onClick={props.onSearch || (() => 0)}
          style={{
            backgroundColor: 'black',
            width: 42,
            height: 42,
            cursor: 'pointer',
          }}>
          <SvgIcon.Search color="white" />
        </Widget.FlexCenter>
      </Widget.FlexRow>
    </SearchBarWrapper>
  );
}

const SearchBarWrapper = styled.div`
  position: relative;
  padding: 32px 50px 20px;

  & .search-input {
    border: none;
    outline: none;
    height: 20px;
    padding: 0 10px;
    font-size: 18px;
  }

  @media screen and (max-width: ${props => props.breakpoints.tablet}px) {
    padding: 0px;
  }
`;

const DropdownWrapper = styled.div`
  & > .content {
    position: absolute;
    z-index: 1;
    width: 100%;
    left: 0px;
    top: 0px;
    background-color: white;
    padding-bottom: 20px;

    & > .header {
      padding: 32px 50px 20px;
    }
    & > .brand-item {
      font-size: ${FontSize.meta}px;
      line-height: ${LineHeight.meta}px;
      padding: 3px 50px;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: flex-end;
      & > .display {
      }
    }

    & > .brand-item.active {
      font-weight: bold;
      & > .display {
        border-bottom: 1px solid ${Color.mainDark};
      }
    }
  }

  & > .backdrop {
    opacity: ${props => (props.open ? '1' : '0')};
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: calc(
      100vh - var(--navbar-height)
    ); /* this is walkaround to set height */
    position: absolute;
    top: 0px;
    left: 0px;
  }
`;

export default StoreSearchBar;
